<template>
  <div class="container">
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-w="5">
        <select v-model="selectedClients" multiple required class="users">
          <option disabled value>选择学员</option>
          <option
            v-for="(user, userIdx) in trainerUsers"
            v-bind:value="user.id"
            v-bind:key="userIdx"
          >{{user.name}}</option>
        </select>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="flex-start" vs-w="7">
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <span>选择套用模板的开始的日子：</span>
            <!-- <select v-model="startDay" required @change="startDayChanged($event)"> -->
            <select v-model="startDay" required>
              <option disabled selected hidden>选择起始</option>
              <optgroup
                v-for="(week, weekIdx) in weekRange"
                v-bind:key="weekIdx"
                :label="'第'+(weekIdx+1)+'周'"
              >
                <option
                  v-for="(day, dayIdx) in week"
                  v-bind:value="weekIdx*7+dayIdx+1"
                  v-bind:key="dayIdx"
                >{{'第' + (weekIdx*7+dayIdx+1) + '天'}}</option>
              </optgroup>
            </select>
          </div>
          <div class="vx-col w-full">
            <span>选择开始的日期：</span>
            <datepicker
              :inline="true"
              :language="lan.zh"
              :value="startDue"
              :open-date="startDue"
              @selected="startDueChanged"
            ></datepicker>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="flex-end" vs-w="12">
        <vs-button color="primary" @click="confirm">确定</vs-button>
      </vs-col>
    </vs-row>
  </div>
</template>


<script>
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";

// import vSelect from "vue-select";
// import Multiselect from "vue-multiselect";

import { mapState } from "vuex";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

export default {
  mixins: [baseFun],

  props: ["program"],

  data() {
    return {
      lan: lang,
      startDay: 1,
      selectedClients: [],
      startDue: new Date()
    };
  },

  created() {
    this.$store.dispatch("getClients", { trainer_id: this.activeTrainer.id });

    let program = this.program;
    if (program && program.workout_ids.length)
      this.$store.dispatch("getWorkouts", { ids: program.workout_ids });
  },

  watch: {
    program: function(newValue) {
      if (newValue && newValue.workout_ids.length)
        this.$store.dispatch("getWorkouts", { ids: newValue.workout_ids });
    }
  },

  computed: {
    // client users of current trainner
    trainerUsers() {
      var trainerClients = this.clients.filter(
        item =>
          item.state == "active" && item.trainer_id == this.activeTrainer.id
      );
      var trainerUsers = [];
      trainerClients.forEach(item =>
        trainerUsers.push(this.getUser(item.user_id))
      );
      return trainerUsers;
    },

    weekRange() {
      let weeks = [];
      let weekNum = this.program.length / 7;
      for (let i = 0; i < weekNum; i++) {
        let week = [];
        for (let j = 1; j <= 7; j++) {
          let position = i * 7 + j;
          week.push(position);
        }
        weeks.push(week);
      }
      return weeks;
    },

    ...mapState([
      "activeTrainer",
      "organizations",
      "trainers",
      "clients",
      "users",
      "groups",
      "workouts",
      "workout_items",
      "activeUser",
      "activeOrganization",
      "programs",
      "program_assignments"
    ])
  },

  methods: {
    confirm() {
      if (!this.selectedClients.length || !this.startDue || !this.startDay)
        return;

      this.$emit("confirm", {
        startPosition: this.startDay,
        user_ids: this.selectedClients,
        startDue: this.startDue,
        program: this.program
      });
    },

    // should check if the client due is empty for the selected date range
    // TODO:
    // startDayChanged(event) {
    //   console.log(event);
    //   // this.startDay = event;
    // },

    startDueChanged(event) {
      // console.log(event);
      this.startDue = event;
    }
  },

  components: {
    // "v-select": vSelect,
    // Multiselect,
    Datepicker
  }
  //
};
</script>

<style scoped>
.container {
  min-height: 400px;
}

select {
  width: 100%;
  padding: 5px 0;
  font-family: "Microsoft YaHei", "微软雅黑", SimSun, "宋体", Heiti, "黑体",
    sans-serif;
  font-size: 1rem;
}

select.users {
  border: none;
  overflow-y: auto;
}
select option {
  padding: 5px;
}
</style>
