<template>
  <div class="programeEdit">
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input v-if="program" class="w-full" label="标准训练计划表" v-model="program.name" />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input v-if="program" class="w-full" label="描述(可选)" v-model="program.description" />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full flex">
        <vs-switch v-if="program" v-model="program.shared" />
        <span class="editSwitch">共享的 - 其他教练可见</span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-2/3 w-full ml-auto">
        <vs-button class="mr-3 mb-2" @click="save()">确认</vs-button>
        <vs-button color="warning" type="border" class="mb-2" @click="close()">取消</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

export default {
  mixins: [baseFun],
  props: ["programOrg"],

  data() {
    return {
      program: null
    };
  },
  created() {
    this.program = this.programOrg
      ? JSON.parse(JSON.stringify(this.programOrg))
      : this.getNewProgram(this.activeOrganization.id, this.activeTrainer.id);
  },
  watch: {
    programOrg() {
      this.program = this.programOrg
        ? JSON.parse(JSON.stringify(this.programOrg))
        : this.getNewProgram(this.activeOrganization.id, this.activeTrainer.id);
    }
  },

  computed: {
    ...mapState([
      "activeTrainer",
      "organizations",
      "trainers",
      "clients",
      "users",
      "groups",
      "workouts",
      "workout_items",
      "activeTrainer",
      "activeUser",
      "activeOrganization",
      "programs",
      "program_assignments"
    ])
  },

  methods: {
    save() {
      if (this.program.name) {
        //check the length & highest position
        let workoutNum = this.program.workout_ids
          ? this.program.workout_ids.length
          : 0;
        if (!this.program.length)
          this.program.length = workoutNum ? workoutNum : 1;
        if (this.program.id) {
          this.$store.dispatch("updateProgram", {
            program: this.program,
            vm: this
          });
        } else {
          this.$store.dispatch("createProgram", {
            program: this.program,
            vm: this
          });
        }
        this.close();
      }
    },

    close(){
      this.$emit("hide");
    }
  }
};
</script>

<style scoped>
.editSwitch {
  padding: 0 5px;
}
</style>
