
<template>
  <div class="programs">
    <vs-card>
      <!--list-->
      <vs-table :data="filteredPrograms" search>
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <h3>标准训练计划表</h3>
          <vs-button
            @click="newProgram"
            color="primary"
            type="flat"
            icon="add"
            class="flex flex-end"
          >添加标准训练计划表</vs-button>
          <vs-popup title="新建标准训练计划表" :active.sync="popupProgramEdit">
            <ProgramEdit :programOrg="null" @hide="popupProgramEdit=false" />
          </vs-popup>
        </div>

        <template slot="thead">
          <vs-th class="list-days">天数</vs-th>
          <vs-th sort-key="name">名称</vs-th>
          <vs-th>活动学员</vs-th>
          <vs-th>创建人</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td class="list-days">{{tr.length}}</vs-td>
            <vs-td>
              <div @click="$router.push({path:'/programs/'+ tr.id +'/workouts'})">
                <span class="p-name">{{ tr.name }}</span>
                <span class="p-desc">{{tr.description}}</span>
              </div>
            </vs-td>
            <vs-td>
              <vs-button
                type="flat"
                icon="assignment_ind"
                @click="showAssign(tr)"
                class="action-assign"
              >指派给学员 ({{tr.program_assignment_ids?tr.program_assignment_ids.length:0}})</vs-button>
              <vs-button
                type="flat"
                color="danger"
                v-if="activeTrainer.super_admin || activeTrainer.id==tr.trainer_id"
                @click="deleteProgram({program:tr, vm:this})"
              >删除</vs-button>
            </vs-td>
            <vs-td :data="tr.trainer_id">
              <vs-avatar
                :src="getTrainerImageURL(tr.trainer_id)"
                :text="getTrainerUserName(tr.trainer_id)"
              />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <!--assgin-->
      <vs-popup
        v-if="activeProgram"
        :title="'训练计划指派 - '+activeProgram.name"
        :active.sync="popupAssign"
      >
        <ProgramAssign :program="activeProgram" @hide="popupAssign=false" @confirm="assignProgram" />
      </vs-popup>
    </vs-card>
  </div>
</template>

<script>
// import * as sample from "@/store/samples/coach/program/program.js";
import ProgramEdit from "@/views/components/coach/program/ProgramEdit.vue";
import ProgramAssign from "@/views/components/coach/program/ProgramAssign.vue";

import { mapState, mapActions } from "vuex";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

export default {
  mixins: [baseFun],

  data() {
    return {
      popupProgramEdit: false,
      popupAssign: false,

      activeProgram: null
    };
  },

  created() {
    this.$store.commit("coachPageIdx", 3);
    // console.log(sample.programs.length);

    // sample.programs.sort((a, b) => b[highest_position] - a[highest_position]);

    // this.programs = sample.programs;
    // this.program_assignments = sample.program_assignments;
    this.$store.dispatch("getPrograms", this.activeOrganization.id);
  },

  computed: {
    filteredPrograms() {
      let organization_id = this.activeOrganization.id;
      let trainer_id = this.activeTrainer.id;
      let super_admin = this.activeTrainer.super_admin;
      return this.programs?this.programs.filter(item => item.organization_id == organization_id && (trainer_id == item.trainer_id || super_admin || item.shared)):[];
    },

    ...mapState([
      "activeTrainer",
      "organizations",
      "trainers",
      "clients",
      "users",
      "groups",
      "workouts",
      "workout_items",
      "activeTrainer",
      "activeUser",
      "activeOrganization",
      "programs",
      "program_assignments"
    ])
  },

  components: {
    ProgramEdit,
    ProgramAssign
  },

  methods: {
    newProgram() {
      this.popupProgramEdit = true;
    },

    showAssign(program) {
      this.activeProgram = program;
      this.popupAssign = true;
    },

    assignProgram(payload) {
      // hide pupup
      this.popupAssign = false;

      // get clients want to assign
      let client_ids = [];
      this.clients.forEach(item => {
        if (payload.user_ids.includes(item.user_id)){
          client_ids.push(item.id);
        }
      });

      let start_position = payload.startPosition;
      let highest_position = payload.program.highest_position;

      // get start due, end due
      let start_date = new Date(payload.startDue);
      start_date = start_date.addDays(start_position-1);
      let end_date = start_date.addDays(highest_position - start_position);
      start_date = this.getDue(start_date);
      end_date = this.getDue(end_date);

      // /api/programs/:id/assign_job
      this.$store.dispatch("assignJob2",
        {
          vm: this,
          program_id: payload.program.id,
          highest_position: highest_position,
          params:{
            "client_ids":client_ids,
            "group_ids":[],
            "start_date":start_date,
            "end_date":end_date,
            "start_position":start_position,
            "notify_client":false
          }
        });

      // // create workouts in program to all selected clients
      // let program = this.activeProgram;
      // let trainer_id = this.activeTrainer.id;

      // // get clients want to assign
      // let clients = this.clients.filter(item =>
      //   payload.user_ids.includes(item.user_id)
      // );

      // // update program program_assignment_ids
      // let programUpdate = JSON.parse(JSON.stringify(program));
      // if (!programUpdate.program_assignment_ids) programUpdate.program_assignment_ids= [];
      // let toUpdate = false;
      // clients.forEach(item=>{
      //   if (!programUpdate.program_assignment_ids.includes(item.id)){
      //     toUpdate = true;
      //     programUpdate.program_assignment_ids.push(item.id);
      //   }
      // });
      // if (toUpdate) dispatch("updateProgram",  { program: programUpdate });


      // // only copy workouts that later or equal the selected day
      // let workouts = this.workouts.filter(
      //   item =>
      //     program.workout_ids.includes(item.id) &&
      //     item.position >= payload.startPosition
      // );

      // // start assign - copy all workouts to clients
      // workouts.forEach(workout => {
      //   let newWorkout = JSON.parse(JSON.stringify(workout));

      //   // prepare workout for each client
      //   clients.forEach(client => {
      //     newWorkout.client_id = client.id;
      //     newWorkout.trainer_id = trainer_id;
      //     newWorkout.program_id = program.id;
      //     newWorkout.program_name = program.name;
      //     newWorkout.id = 0;
      //     newWorkout.workout_item_ids = null;

      //     let due = new Date(payload.startDue.getTime());
      //     due.setDate(due.getDate() + newWorkout.position - 1 );
      //     newWorkout.due = (due.getFullYear() + "-" + (due.getMonth() + 1) + "-" + due.getDate())

      //     // rebuild workout_items
      //     let workout_items = [];
      //     newWorkout.workout_items.forEach(workout_item => {
      //       let newWorkoutItem = JSON.parse(JSON.stringify(workout_item));
      //       newWorkoutItem.id = 0;
      //       newWorkoutItem.workout_id = 0;

      //       workout_items.push(newWorkoutItem);
      //     });
      //     newWorkout.workout_items = workout_items;

      //     // console.log(newWorkout);
      //     dispatch("createWorkout",  { workout: newWorkout });
      //   });
      // });

    },

    ...mapActions({
      deleteProgram: "deleteProgram"
    })
  }
};
</script>


<style scoped>
.list-days {
  white-space: nowrap;
}

.p-name,
.p-desc {
  display: block;
}
.p-desc {
  font-size: 85%;
  color: #a2b4c3;
}

.action-assign {
  float: left;
}
</style>
